<template>
  <div>
    <h3 style="text-align:center;width:100%" v-if="custId!='admin'">平均分 ：{{rateAverage}}</h3>
    <div class="container">
      <div v-for="(item, i) in comments" :key="i" class="card">
        <div style="font-size: 16px;">{{item.nameStore}}</div>
        <div>
          <span style="color:#659ef4;cursor:pointer" @click="toUser(item.idUser)">{{item.nickName}}</span>
          <span style="margin: 0 20px;">{{ item.logchannel }} . {{ item.typeName }} .</span>
          <span style="margin: 0 20px;">{{item.updatedTime}}</span>
          <el-button type="danger" size="mini" @click="delComment(item)" v-if="custType=='admin'">删除</el-button>
        </div>
        <div style="margin:20px 0">{{item.comment}}</div>
        <div>
          <img
            v-for="(img,index) in item.imgs"
            :key="index+'a'"
            :src="baseurl+img"
            class="imgwrap"
            alt
            @click="checkPic(img)"
          />
        </div>
        <div v-if="item.child">
          <div v-for="(child,index) in item.child" :key="index+'b'">
            <div style="color:#777">
              <span v-if="child.replyType">回复</span>
              <span v-else>追评</span>
              <span
                style="margin:0 20px;color:#659ef4;cursor:pointer"
                @click="toUser(child.user.idUser)"
              >{{ child.user.nickName }}</span>
              <span>{{ child.logchannel }} .</span>
              <span>({{child.genTime}})</span>
              <el-button
                type="danger"
                size="mini"
                @click="delComment(child)"
                v-if="custType=='admin'"
              >删除回复</el-button>
            </div>
            <div style="margin:10px 0">{{child.comment}}</div>
          </div>
        </div>
        <div style="margin-top: 20px;margin-left:20px">
          <el-button
            type="success"
            size="mini"
            @click="authCommentFn(item.id)"
            v-if="custType=='admin'"
          >通过</el-button>
        </div>
      </div>
    </div>
    <el-pagination
      :page-size="count"
      :pager-count="9"
      :current-page.sync="pageNumber"
      layout="total, prev, pager, next"
      :total="total"
      hide-on-single-page
      @current-change="handleCurrentChange"
    ></el-pagination>

    <el-dialog :visible.sync="showImg" width="900px">
      <div class="imgsetbox">
        <img :src="imgSet" width="500px" alt />
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { authComment, adminGetComments } from "@/api/api";
export default {
  props: ["custId"],
  data() {
    return {
      baseurl: "https://server.solar960.com",
      show: false,
      showImg: false,
      imgSet: "",
      pageNumber: 1,
      total: 0,
      rateAverage: "",
      comments: [],
      id: "",
      custType: "",
      count: 10,
      listHead: {
        comment: "评论",
        rate: "打分",
        replyType: "评论人",
        idUser: "用户ID",
        idStore: "商家ID",
        avatarUrl: "头像",
        nickName: "昵称",
        nameStore: "商家名称",
        genTime: "时间"
      }
    };
  },
  created() {
    if (this.custId === "admin") {
      this.id = "admin";
      this.custType = "admin";
    } else if (this.custId === "sight") {
      this.id = this.$route.query.id;
      this.custType = "sight";
    } else if (this.custId === "route") {
      this.id = this.$route.query.id;
      this.custType = "route";
    } else if (this.custId === "tripGuide") {
      this.id = this.$route.query.id;
      this.custType = "tripGuide";
    } else {
      this.id = this.$route.query.idUser;
      this.custType = "user";
    }
    this.adminGetCommentFn();
  },
  methods: {
    adminGetCommentFn() {
      const that = this;
      adminGetComments({
        custId: that.id,
        page: that.pageNumber,
        count: that.count,
        custType: that.custType
      }).then(res => {
        if (res.result) {
          that.comments = res.data.comments;
          that.total = res.data.count;
          that.rateAverage = res.data.commentRate;
          this.$emit("count", res.data.count);
        }
      });
    },

    checkPic(item) {
      this.imgSet = "https://server.solar960.com" + item;
      this.showImg = true;
    },
    handleCurrentChange(val) {
      this.pageNumber = val;
      this.adminGetCommentFn();
    },

    toUser(idUser) {
      this.$router.push({
        name: "userdetail",
        query: { idUser: idUser }
      });
    },

    delComment(comment) {
      const that = this;
      this.$prompt("请输入删除的原因", "删除", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        inputPattern: /^\S{1,}$/,
        inputErrorMessage: "请输入删除的原因"
      })
        .then(({ value }) => {
          authComment({
            id: comment.id,
            value: value,
            type: 2
          }).then(res => {
            if (res.result) {
              that.$message({
                type: "success",
                message: "删除成功"
              });
              let index;
              if (comment.parrentId == 0) {
                index = that.comments.findIndex(item => {
                  return item.id == comment.id;
                });
                that.comments.splice(index, 1);
              } else {
                index = that.comments.findIndex(item => {
                  return item.id == comment.parrentId;
                });
                let childIndex = that.comments[index].child.findIndex(item => {
                  return item.id == comment.id;
                });
                that.comments[index].child.splice(childIndex, 1);
              }
            }
          });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "取消"
          });
        });
    },
    authCommentFn(id) {
      const that = this;
      authComment({
        id: id,
        value: "",
        type: 1
      }).then(res => {
        if (res.result) {
          that.$message({
            type: "success",
            message: "已检查"
          });
          let index = this.comments.findIndex(item => {
            return item.id == id;
          });
          this.comments.splice(index, 1);
        }
      });
    }
  }
};
</script>

<style scoped>
.container {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-wrap: wrap;
  font-size: 13px;
}
.card {
  border: 1px solid #999;
  padding: 20px;
  margin: 10px;
  border-radius: 10px;
  width: 28%;
}
.imgsetbox {
  padding: 20px;
  box-sizing: border-box;
  width: 900px;
}

.imgwrap {
  width: 60px;
  height: 60px;
  margin: 0 10px 10px 0;
}
</style>