<template>
	<div>
		<div class="title-g">
			<h3>评论</h3>
		</div>
		<commentpage :custId="'admin'" />
	</div>
</template>

<script>
	import commentpage from "../components/CommentPage";
	import { } from "@/api/api"
	export default {
		components: {
			commentpage,
		},
		data() {
			return {};
		},
	};
</script>

<style>
</style>